import React, { Fragment, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import Markdown from 'react-markdown'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import Layout from '../layouts'
import HeaderNavbar from '../components/HeaderNavbar'
import {formatCurrencyString, useShoppingCart} from '../context'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

export default ({ data, pageContext }) => {
  const product = data.gcms.product
  const [state, setState] = useState({
    defaultValues: {},
    product: {
      sku: product.id,
      id: product.id,
      name: product.name,
      image: product.images[0].url,
      price: product.price,
      metadata: {},
      url: `/services/${product.collections[0].slug}/${product.productSlug}`
    },
    quantity: product.quantity[0],
    selectedSize: null,
    sizes: null,
    priceBySize: null,
    subTotal: 0
  })
  const {addItem} = useShoppingCart()
  const optionKeys = ['orientation', 'grommets', 'hem', 'materials', 'polePockets', 'printedSides', 'sizes']
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4
  }

  useEffect(() => {
    const defaultValues = {}
    Object.entries(product).map(([key, item], index) => {
      if (optionKeys.includes(key)) {
        if (item.length > 0) {
          switch (key) {
            case 'grommets':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'hem':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'materials':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'orientation':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'printedSides':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'polePockets':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'sizes':
              // code block
              defaultValues[key] = item[0].label
              break
            default:
            // code block
          }
        }
      }
    })

    setState({...state, defaultValues: defaultValues, subTotal: product.price})

    /*
    const custom = product.category.options.reduce((temp, {value, price, groupBy}) => {
      if (!temp[groupBy]) {
        console.log(value)
        temp[groupBy] = {
          value: value,
          price: price
        }
      }
      return temp
    }, {})
    setTempProduct({...tempProduct, custom})
    */
  }, [])

  useEffect(() => {
    if (Object.keys(state.defaultValues).length > 0) {
      addTotals()
    }
  }, [state.defaultValues, state.quantity])

  const addTotals = () => {
    let total = product.price
    let price = product.price
    if (state.quantity > 10) {
      // discounted_price = original_price - (original_price * discount / 100)
      price = price - (price * 0.1 / 100)
    }

    Object.entries(state.defaultValues).map(([key, value], index) => {
      const item = product[key].find(item => item.value === value)
      if (item) {
        if (item.price) {
          // total += item.price
        }
      }
    })

    total = price
    total *= state.quantity

    setState({...state, subTotal: total})
  }

  const handleQuantity = event => {
    setState({...state, quantity: event.target.value})
  }

  const addToCart = () => {
    let metadata = {}
    Object.entries(state.defaultValues).map(([key, value], index) => {
      const item = product[key].find(item => item.value === value)
      metadata = {
        parcel: item.parcel
      }
      switch (key) {
        case 'grommets':
          // code block
          metadata = {...metadata, Grommet: value}
          break
        case 'hem':
          // code block
          metadata = {...metadata, Hem: value}
          break
        case 'materials':
          // code block
          metadata = {...metadata, Material: value}
          break
        case 'orientation':
          // code block
          metadata = {...metadata, Orientation: value}
          break
        case 'printedSides':
          // code block
          metadata = {...metadata, ['Printed Side']: value}
          break
        case 'polePockets':
          // code block
          metadata = {...metadata, ['Pole Pocket']: value}
          break
        case 'sizes':
          // code block
          metadata = {...metadata, Size: value}
          break
        default:
          // code block
      }
    })
    console.log(metadata)

      addItem({
        sku: product.id,
        name: product.name,
        image: product.images[0].url,
        price: product.price,
        metadata,
        currency: 'USD'}, state.quantity)
  }

  return (
    <Layout>
      <HeaderNavbar/>
      <Container maxWidth='md'>
        <Box marginBottom={5} marginTop={3}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link color='inherit' to='/'>Home</Link>
            <Link color='inherit' to={`/${pageContext.collection.slug}`}>
              {pageContext.collection.name}
            </Link>
            <Typography color='textPrimary'>
              {product.name}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item sm={6} style={{textAlign: 'center'}}>
              <img
                alt={product.name}
                src={product.images[0].url}
                width={'75%'}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography variant={'h4'} gutterBottom>
                {product.name}
              </Typography>
              {product.shortDescription && (
                <Markdown
                  source={product.shortDescription.markdown}
                  escapeHtml={false}
                />
              )}
              <Box marginBottom={4}>
                <FormControl variant='outlined' fullWidth>
                  <Typography variant='subtitle2' gutterBottom>
                    Quantity
                  </Typography>
                  <Select
                    labelId='outlined-age-native-simple'
                    id='outlined-age-native-simple-select'
                    value={state.quantity}
                    onChange={handleQuantity}
                    input={<BootstrapInput />}
                  >
                    {product.quantity.map(amount => (
                      <MenuItem key={amount} value={amount}>{amount}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            {state.selectedSize && (
              <img src={state.selectedSize} width={'100%'} />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/*state.variants['Size'] && (
              <Box marginBottom={3}>
                <FormControl variant='outlined' fullWidth>
                  <Typography variant='subtitle2' gutterBottom>
                    Size
                  </Typography>
                  <Select
                    labelId='outlined-age-native-simple'
                    id='outlined-age-native-simple-select'
                    value={state.defaultValues.sizes}
                    onChange={handleSizeChange}
                    input={<BootstrapInput />}
                  >
                    {state.sizes.map(size => (
                      <MenuItem key={size.label} value={size.label}>{size.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <Typography variant='subtitle2' gutterBottom>
                    Size
                  </Typography>
                  <Grid container spacing={3}>
                    {state.sizes.map((size, index) => (
                      <Grid key={`${size}-${index}`} id={size} item xs={12} sm={4}>
                        <Button variant='outlined' color={size.label === state.defaultValues.sizes ? 'primary' : 'default'} fullWidth onClick={() => handleSizeChange(size)}>{size.label}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
              </Box>
            )*/}
          </Grid>
        </Grid>
        <Box marginBottom={8}>
          <Grid container>
            <Grid item xs={12} sm={6}>&nbsp;</Grid>
            <Grid item xs={12} sm={6}>
              <Box alignItems='center' justifyContent='space-around' display='flex'>
                <Box alignItems='center' justifyContent='space-around' display='flex'>
                  <Typography variant={'subtitle2'} style={{fontSize: 16, marginRight: 6}}>
                    Price:
                  </Typography>
                  <Typography variant={'h6'} style={{color: '#01b901'}}>
                    {formatCurrencyString({
                      value: state.subTotal,
                      currency: 'USD',
                      language: 'en-US'
                    })}
                  </Typography>
                </Box>
                {/*
                  <Button
                    variant='contained'
                    color='primary'
                    className='snipcart-add-item'
                    data-item-id={product.id}
                    data-item-price={subTotal}
                    data-item-url={`/services/${product.collections[0].slug}/${product.productSlug}`}
                    data-item-image={product.images[0].url}
                    data-item-name={product.name}
                  >
                    Add To Cart
                  </Button>
                  */}
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={addToCart}>
                  Add to Cart
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {product.accessories.length > 0 && (
          <Box marginBottom={8}>
            <Typography variant={'h4'} gutterBottom>Accessories (Optional)</Typography>
            <Slider {...settings}>
              {product.accessories.map((accessory, index) => (
                <Fragment key={index}>
                  {accessory.images.length > 0 && (
                    <Box marginBottom={2} display={'flex'} alignItems='center' justifyContent='center'>
                      <img src={accessory.images[0].url} width={'80%'} alt={accessory.name} />
                    </Box>
                  )}
                  <Box marginBottom={2} display={'flex'} alignItems='center' justifyContent='center'>
                    <Typography variant='subtitle1' align='center'>
                      {accessory.name}
                    </Typography>
                  </Box>
                  <Box marginBottom={2} display={'flex'} alignItems='center' justifyContent='center'>
                    <Typography variant={'h6'} style={{marginLeft: 16, marginRight: 16}}>
                      {formatCurrencyString({
                        value: accessory.price,
                        currency: 'USD',
                        language: 'en-US'
                      })}
                    </Typography>
                  </Box>
                  <Box marginBottom={2} display={'flex'} alignItems='center' justifyContent='center'>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={() => addItem({
                        sku: accessory.id,
                        name: accessory.name,
                        image: accessory.images[0].url,
                        price: accessory.price,
                        currency: 'USD'}
                      )}>
                      Add to Cart
                    </Button>
                  </Box>
                </Fragment>
              ))}
            </Slider>
          </Box>
        )}
        {product.description && (
          <Box marginBottom={8}>
            <Markdown
              source={product.description.markdown}
              escapeHtml={false}
            />
          </Box>
        )}
        {product.sections && (
          <Grid container spacing={3}>
            {product.sections.map(({id, headline, description, relations}, index) => (
              <Box key={index} marginBottom={8}>
                <Grid item xs={12}>
                  <Box marginBottom={5}>
                    <Typography variant='h4'>
                      {headline}
                    </Typography>
                    <Typography variant='subtitle1'>
                      {description.markdown}
                    </Typography>
                  </Box>
                  <Box>
                    {relations.map(({id, title, details, layout}, index) => (
                      <Grid key={index} container spacing={3}>
                        {title && (
                          <Grid item xs={12}>
                            <Typography variant='h6'>
                              {title}
                            </Typography>
                          </Grid>
                        )}
                        {details.map(({id, title, shortDescription, image, benefits, category}, index) => (
                          <Fragment key={index}>
                            {layout !== 'Rounded_Image_Tile_Horizontal' && (
                              <Grid item sm={12 / details.length}>
                                <Box marginBottom={2}>
                                  <img src={image.url} width={'100%'} alt={title} />
                                </Box>
                                <Box marginBottom={3}>
                                  <Typography variant='h6' align='center' gutterBottom>
                                    {title}
                                  </Typography>
                                  {benefits.length === 0 && (
                                    <Typography variant='body1' align='center' gutterBottom>
                                      {shortDescription}
                                    </Typography>
                                  )}
                                  {benefits.length > 0 && (
                                    <Box style={{display: 'flex', justifyContent: 'center'}}>
                                      <ul>
                                        {benefits.map(b => (
                                          <li key={b}>{b}</li>
                                        ))}
                                      </ul>
                                    </Box>
                                  )}
                                </Box>
                                {category && (
                                  <Box>
                                    <Typography variant='subtitle1' align='center'>
                                      <Box fontWeight='fontWeightBold'>
                                        Best For:
                                      </Box>
                                    </Typography>
                                    <Typography variant='body2' align='center'>
                                      {category.bestFor}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            )}
                            {layout === 'Rounded_Image_Tile_Horizontal' && (
                              <Grid item sm={12 / details.length}>
                                <Box marginBottom={5} style={{display: 'flex', alignItems: 'center'}}>
                                  <img src={image.url} style={{marginLeft: 10, marginRight: 10, width: '33.33%'}} alt={title} />
                                  <Box>
                                    <Typography variant='h6' gutterBottom>
                                      {title}
                                    </Typography>
                                    {benefits.length === 0 && (
                                      <Typography variant='body1' gutterBottom>
                                        {shortDescription}
                                      </Typography>
                                    )}
                                  </Box>
                                  {benefits.length > 0 && (
                                    <Box style={{display: 'flex', justifyContent: 'center'}}>
                                      <ul>
                                        {benefits.map(b => (
                                          <li key={b}>{b}</li>
                                        ))}
                                      </ul>
                                    </Box>
                                  )}
                                </Box>
                                {category && (
                                  <Box>
                                    <Typography variant='subtitle1' align='center'>
                                      <Box fontWeight='fontWeightBold'>
                                        Best For:
                                      </Box>
                                    </Typography>
                                    <Typography variant='body2' align='center'>
                                      {category.bestFor}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            )}
                          </Fragment>
                        ))}
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              </Box>
            ))}
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($productSlug: String) {
    gcms {
      product(where: {productSlug: $productSlug}, stage: DRAFT) {
        id
        name
        shortDescription {
          markdown
        }
        price
        description {
          markdown
        }
        images {
          url
        }
        detail {
          benefits
        }
        productSlug
        category {
          id
          name
          options {
            label
            value
            height_in
            toolTip
            width_in
            price
          }
        }
        customize
        quantity
        collections {
          slug
        }
        accessories {
          name
          images {
            url
          }
          price
          collections {
            slug
          }
          quantity
        }
        sections {
          id
          headline
          description {
            markdown
          }
          relations {
            __typename
            ... on GCMS_Advise {
              id
              title
              description {
                markdown
              }
              details {
                title
                shortDescription
                image {
                  url
                }
                benefits
                category {
                  bestFor
                }
              }
              layout
            }
          }
        }
      }
    }
  }
`
